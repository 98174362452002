/* :root {
  --main-color: #fff;
  --second-color: #000;
  --size: 300px;
  --border: 2px;
}

.circle {
  width: 300px;
  height: 300px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transform: rotate(-180deg);
  background: conic-gradient(
    from 180deg at 50% 50%,
    var(--second-color) 0deg,
    rgba(var(--second-color), 0) 360deg
  );
  animation-name: rotation;
  animation-duration: 1500ms;
  animation-timing-function: linear;
  animation-duration: infinite;
}

.circle__inner {
  width: calc(var(--size) - var(--border));
  height: calc(var(--size) - var(--border));
  border-radius: 50%;
  background-color: var(--main-color);
  animation-name: shadow 1500ms linear infinite;
}

@keyframes shadow {
  0% {
    box-shadow: 0 0 0 var(--border) var(--main-color);
  }
  100% {
    box-shadow: 0 0 0 0 var(--main-color);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
} */

.circleSvg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 10rem;
  animation: rotate 3.6s linear infinite;
  margin: 0 auto;
}

circle {
  fill: none;
  /* stroke: #cb2d6f; */
  stroke-width: 8px;
  stroke-dasharray: 300;
  animation: outline 2s cubic-bezier(0.77, 0, 0.18, 1) infinite;
}

@keyframes outline {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 300;
  }
  100% {
    stroke-dashoffset: 600;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(-1turn);
  }
}
