.dropdown {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1150px) {
  .dropdown {
    width: 90%;
    margin: 20px auto;
  }
}
