.statistics {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #fff;
}

.statistics-left {
  width: 40%;
  height: 100vh;
  margin: 0;
  padding: 20px;
}

@media screen and (max-width: 1150px) {
  .statistics {
    flex-direction: column;
    border-radius: 0;
    height: auto;
  }
  .statistics-left {
    width: 100%;
    height: auto;
    padding: 0;
    border: none;
    border-bottom: 2px solid #ddd;
  }
}
